function irakurriCSVfitxeroa(eremuaIzena, urla, eremuaEmaitza) {
    document.body.style.cursor = 'wait';
    var input = document.getElementById(eremuaIzena);
    var eremu_id = document.getElementById('idNagusia');
    var id = 0;
    if (eremu_id != null) id = eremu_id.value;

    var lerroKopOna = 0;

    if (input.value == '') {
        alert('Ez dut topatu fitxategirik.');
        document.body.style.cursor = 'default';
        return false;
    }

    var file = input.files[0];
    var extension = file.name.split('.').pop();

    if (extension != 'csv') {
        alert('Fitxategiaren formatua ez da zuzena. CSV izan behar da.');
        document.body.style.cursor = 'default';
        return false;
    }

    var fr = new FileReader();

    fr.onload = function () {
        var fitxeroa = fr.result;

        fitxeroa = fitxeroa.replace(/\n/g, 'LERRO_SALTO');

        var lerroak = fitxeroa.split('LERRO_SALTO');
        var lerroKop = lerroak.length;

        var ruta = '/' + urla + '/csv/';
        if (id > 0) ruta = '/' + urla + '/csv/' + id;

        for (var i = 1; i < lerroKop; i++) {
            if (lerroak[i] != '') {
                lerroKopOna++;
                $.ajax({
                    data: 'f=' + lerroak[i],
                    url: ruta,
                    success: function (data) {
                        if (data.status != '200') {
                            alert(urla + ' Arazoak fitxeroa kargatzeko. Saiatu berriro mesedez');
                        } else {
                            // alert (data.emaitza);
                            if (data.emaitza != '') $('#' + eremuaEmaitza).append('<br>' + data.emaitza);
                        }
                    },
                    error: function (xhr, textStatus, thrownError) {
                        erroreakIdatzi(xhr, textStatus, thrownError)
                    }
                });
            }
        }
        $('#emaitza').append(' Guztira: ' + lerroKopOna + ' lerro');
        document.body.style.cursor = 'default';
    };

    fr.readAsText(file);
}