function nireLokalizazioak(entitatea) {
    $.get('/entitateak_lokalizazioak/' + entitatea, function (data) {
        if (typeof(aukeratua) === "undefined" || aukeratua === null) aukeratua = 0;
        var balioak = [];
        theOption = document.createElement("option");
        theText = document.createTextNode('--');
        theOption.appendChild(theText);
        theOption.setAttribute("value", "0");
        balioak.push(theOption);
        for (var i in  data) {
            theOption = document.createElement("option");
            theText = document.createTextNode(data[i].izena);
            theOption.appendChild(theText);
            theOption.setAttribute("value", data[i].id);
            if (aukeratua == data[i].id) {
                theOption.setAttribute("selected", true);
            }
            balioak.push(theOption);
        }
        $('#entitateaLokalizazioa_id').html(balioak);
    });
}

function nireOrdezkariLokalizazioak(entitatea) {
    $.get('/entitateak_lokalizazioak/' + entitatea, function (data) {
        if (typeof(aukeratua) === "undefined" || aukeratua === null) aukeratua = 0;
        var balioak = [];
        theOption = document.createElement("option");
        theText = document.createTextNode('--');
        theOption.appendChild(theText);
        theOption.setAttribute("value", "0");
        balioak.push(theOption);
        for (var i in  data) {
            theOption = document.createElement("option");
            theText = document.createTextNode(data[i].izena);
            theOption.appendChild(theText);
            theOption.setAttribute("value", data[i].id);
            if (aukeratua == data[i].id) {
                theOption.setAttribute("selected", true);
            }
            balioak.push(theOption);
        }
        $('#ordezkariaLokalizazioa_id').html(balioak);
    });
}

function nirePostakodeak(herria) {
    $.get('/postakodeak/' + herria, function (data) {
        if (typeof(aukeratua) === "undefined" || aukeratua === null) aukeratua = 0;
        var balioak = [];
        theOption = document.createElement("option");
        theText = document.createTextNode('--');
        theOption.appendChild(theText);
        theOption.setAttribute("value", 0);
        balioak.push(theOption);
        for (var i in  data) {
            theOption = document.createElement("option");
            theText = document.createTextNode(data[i].pk);
            theOption.appendChild(theText);
            theOption.setAttribute("value", data[i].id);
            if (aukeratua == data[i].id) {
                theOption.setAttribute("selected", true);
            }
            balioak.push(theOption);
        }
        $('#postakodea_id').html(balioak);
    });
}

function nireNorbanakoak(entitatea) {
    $.get('/entitateak_norbanakoak/' + entitatea, function (data) {
        if (typeof(aukeratua) === "undefined" || aukeratua === null) aukeratua = 0;
        var balioak = [];
        theOption = document.createElement("option");
        theText = document.createTextNode('--');
        theOption.appendChild(theText);
        theOption.setAttribute("value", 0);
        theOption.setAttribute("selected", true);
        balioak.push(theOption);
        for (var i in  data) {
            theOption = document.createElement("option");
            if (data[i].blokeatua == '0')
                theText = document.createTextNode(data[i].izena + ' ' + data[i].abizenak + ' (' + data[i].mugikorra + ')');
            else
                theText = document.createTextNode('[B] ' + data[i].izena + ' (' + data[i].mugikorra + ')');
            theOption.appendChild(theText);
            theOption.setAttribute("value", data[i].id);
            if (aukeratua == data[i].id) {
                theOption.setAttribute("selected", true);
            }
            balioak.push(theOption);
        }
        $('#norbanakoa_id').html(balioak);
    });
}