//TODO AMAIA:: haiserako oharra
/*
         setTimeout(function() {
            toastr.options = {
                closeButton: true,
                progressBar: true,
                showMethod: 'slideDown',
                timeOut: 4000
            };
            toastr.success('{{(Auth::user())?Auth::user()->username: ''}}', 'Oharrak!');
 }, 1300);
*/

function atzeraJoDesgaitu() {
    //honekin history galtzen da
    // window.location.hash = "no-back-button";
    // window.location.hash = "Again-No-back-button" //chrome
    //  window.onhashchange = function () {
    //      window.location.hash = "no-back-button";
    //  }
}

function gordeBotoiaAktibatu() {
    $('.btn-gorde').removeAttr('disabled');
    gordeBai();
}

function goBack() {
    history.go(-1);
}

function gordeFitxaForzatu() {
    document.getElementById("id-btn-gorde").click();
}