function delete_cookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function url_location_garbitu() {
    var url = window.location;

    return url;
}

function url_referrer_garbitu() {
    var url = document.referrer;

    return url;
}

function bilatu_ezabatu(taulaUrl) {
    $('.btn-bilaketara').click(function () {
        delete_cookie("BILATU" + taulaUrl);
        // gordeEz();
    });
}

function bilatu_cookie(name) {
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + name.replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
}


function gordeGabeCookie() {
    var url = url_referrer_garbitu();
    var name = 'GORDEGABE'+url;
    var gordeGabe = bilatu_cookie(name);

    var galdetu = bilatu_cookie('GALDETU');

    if (galdetu == null || galdetu == 'true') {
        var navFitxaBerria = false;

        if (gordeGabe == 'true' && !navFitxaBerria) {
            if (confirm(url + '\nFitxa gorde gabe. Jarraitu nahi duzu aldaketak gorde gabe?'))
                gordeEz();
            else
                goBack();
        }
    } else {
        document.cookie = "GALDETU=true; path=/";
    }
}

function gordeEz() {
    //oraingo url
    var url = url_location_garbitu();
    document.cookie = "GORDEGABE" + url + "=; path=/";
    delete_cookie("GORDEGABE" + url);

    //nondik dator
    var url = url_referrer_garbitu();
    document.cookie = "GORDEGABE" + url + "=; path=/";
    delete_cookie("GORDEGABE" + url);

    document.cookie = "CKEDITORAKTIBATUGORDE=;  path=/";
    delete_cookie("CKEDITORAKTIBATUGORDE");
}


function gordeBai() {
    var url = url_location_garbitu();
    document.cookie = "GORDEGABE" + url + "=true; path=/";
}

function hartuCookie(name) {
    var arg = name + "=";
    var alen = arg.length;
    var clen = document.cookie.length;
    var i = 0;
    while (i < clen) {
        var j = i + alen;
        if (document.cookie.substring(i, j) == arg)
            return "1";
        i = document.cookie.indexOf(" ", i) + 1;
        if (i == 0)
            break;
    }
    return null;
}

function popbox3() {
    $('#info-cookies').toggle();
}

function onartu_cookies() {
    var expire = new Date();
    expire = new Date(expire.getTime() + 7776000000);
    document.cookie = "oharra_cookies=onartu; expires=" + expire;

    var visit = hartuCookie("oharra_cookies");
    if (visit == 1) {
        popbox3();
    }
}