function input_format() {
    $('input').each(function () {
        var blokeatu = $(this).data("blokeatu");
        if (blokeatu == 'blokeatu') {
            $(this).attr('disabled', 'true');
        }
        var fokus = $(this).data("fokus");
        if (fokus == 'fokus') {
            $(this).focus();
        }
        var derrigorrez = $(this).data("derrigorrez");
        if (derrigorrez == 'derrigorrez') {
            $(this).attr('required', 'true');
        }
    });

    $('input').keyup(function (e) {
        if (e.keyCode != 9) gordeBotoiaAktibatu();
        if (e.keyCode == 13) gordeEz();
    });

}

function select_format() {
    $(".select2").change(function () {
        gordeBotoiaAktibatu();
    });

    $('select').change(function () {
        $('.btn-gorde').removeAttr('disabled');
        gordeBai();
    });

    $('select').each(function () {
        var derrigorrez = $(this).data("derrigorrez");
        if (derrigorrez == 'derrigorrez') {
            $(this).attr('required', 'true');
        }
        var blokeatu = $(this).data("blokeatu");
        if (blokeatu == 'blokeatu') {
            $(this).attr('disabled', 'true');
        }
        var ikusgai = $(this).data("ikusgai");
        if (ikusgai == 'hidden') {
            $(this).css('display', 'none');
        }
    });

    $(".select2").select2();

    $('.select2').each(function () {
        var ikusgai = $(this).data("ikusgai");
        if (ikusgai == 'hidden') {
            $(this).css('display', 'none');
        }
    });

    $(".select2new").select2({
        tags: true,
        tokenSeparators: ['Berria:', ','],
        insertTag: function (data, tag) {
            // Insert the tag at the end of the results
            data.push(tag);
        },
        createTag: function (params) {
            var term = $.trim(params.term);
            if (term === '') {
                return null;
            }
            return {
                id: term,
                text: 'Berria Sortu: ' + term,
                newTag: true // add additional parameters
            }
        }
    });
}

function checkbox_fomat() {
    $('input:checkbox').change(function () {
        gordeBotoiaAktibatu();
        var blokeatu = $(this).data("blokeatu");
        if (blokeatu == 'blokeatu') {
            $(this).attr('disabled', 'true');
        }
        var derrigorrez = $(this).data("derrigorrez");
        if (derrigorrez == 'derrigorrez') {
            $(this).attr('required', 'true');
        }
    });
}

function textarea_format() {
    $('textarea').each(function () {
        var blokeatu = $(this).data("blokeatu");
        if (blokeatu == 'blokeatu') {
            $(this).attr('disabled', 'true');
        }
    });

    $('textarea').keyup(function (e) {
        if (e.keyCode != 9) gordeBotoiaAktibatu();
    });
}

function color_format() {
    $(".pick-a-color").change(function () {
        gordeBotoiaAktibatu();
    });

    $(".pick-a-color").pickAColor({
        showSpectrum: false,
        showBasicColors: false,
        allowBlank: true
    });
}